import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import "./incidencias.container.scss";
import { addImagen, crearTicket } from "../../services/service";
import "react-notifications/lib/notifications.css";
import ReactLoading from "react-loading";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ModalSuccessError from "../../components/modal-success-error";

const styles = {
  "&.MuiButton-root": {
    border: "2px black solid",
  },
};

const Incidencias = () => {
  const [subtipos, setSubtipos] = useState([]);
  const [tipo, setTipo] = useState();
  const [subtipo, setSubtipo] = useState();
  const [pais, setPais] = useState();
  const [loading, setLoading] = useState(false);
  const [read, setRead] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [imagen, setImagen] = useState(null);

  const zipFiles = async (idTicket) => {
    const imagen64 = await convertBase64(imagen);
    await addImagen(imagen.name, imagen64, idTicket);
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSend = async (data) => {
    setLoading(true);
    data = {
      ...data,
      tipo_incidencia: tipo.desc,
      subtipo_incidencia: subtipo.desc,
      pais: pais.value
    };
    const res = await crearTicket(data);
    if (res.status === 200) {
      if (imagen) {
        await zipFiles(res.data.id);
      }
      setOpenSuccess(true);
    } else {
      setOpenError(true);
    }
    setLoading(false);
  };

  const cargarSubtipos = (tipo_selected) => {
    const sub = subtipos_incidencias.filter(
      (subtipo) => subtipo.idMadre === tipo_selected?.id
    );
    setSubtipo(null);
    setSubtipos(tipo_selected ? sub : []);
    setTipo(tipo_selected);
  };

  const tipos_incidencias = [
    { id: 1, value: "1. DEVOLUCION CLIENTE", desc: "Devolución cliente" },
    { id: 2, value: "2. MERCANCIA DEFECTUOSA", desc: "Mercancía defectuosa" },
    { id: 3, value: "3. ERRORES ENTREGAS", desc: "Errores entregas" },
    { id: 4, value: "4. NO ENTREGADO", desc: "No entregado" },
    {
      id: 5,
      value: "5.CANCEL./MODIF. PEDIDO",
      desc: "Cancelación/Modificación pedido",
    },
    { id: 6, value: "6. PAGOS", desc: "Pagos" },
    {
      id: 7,
      value: "7. FACTURACION/DOCUMENTACION",
      desc: "Facturación/Documentación incidencias",
    },
  ];

  const subtipos_incidencias = [
    {
      id: 11,
      value: "1.1 Comprado por error",
      idMadre: 1,
      desc: "Comprado por error",
    },
    {
      id: 12,
      value: "1.2 Encontrado más barato",
      idMadre: 1,
      desc: "Encontrado mas barato",
    },
    {
      id: 13,
      value: "1.3 Llegó demasiado tarde",
      idMadre: 1,
      desc: "Llego demasiado tarde",
    },
    {
      id: 14,
      value: "1.4 Ya no lo quiero",
      idMadre: 1,
      desc: "Ya no lo quiero",
    },
    {
      id: 15,
      value: "1.5 Compra no autorizada",
      idMadre: 1,
      desc: "Compra no autorizada",
    },
    {
      id: 16,
      value: "1.6 Otros motivos devolucion cliente",
      idMadre: 1,
      desc: "Otros motivos devolucion cliente",
    },
    {
      id: 21,
      value: "2.1 Producto defectuoso / No funciona bien",
      idMadre: 2,
      desc: "Producto defectuoso / No funciona bien",
    },
    {
      id: 22,
      value: "2.2 Producto recibido dañado. / Embalaje esta bien",
      idMadre: 2,
      desc: "Producto recibido dañado /Embalaje esta bien",
    },
    {
      id: 23,
      value: "2.3 Producto y embalaje recibidos dañados",
      idMadre: 2,
      desc: "Producto y embalaje recibidos dañados",
    },
    {
      id: 24,
      value: "2.4 Otros motivos mercancia defectuosa",
      idMadre: 2,
      desc: "Otros motivos mercancia defectuosa",
    },
    {
      id: 31,
      value: "3.1 Faltan piezas o accesorios",
      idMadre: 3,
      desc: "Faltan piezas o accesorios",
    },
    {
      id: 32,
      value: "3.2 No es el producto que había pedido",
      idMadre: 3,
      desc: "No es el producto que habia pedido",
    },
    {
      id: 33,
      value: "3.3 He recibido mas productos de los pedidos",
      idMadre: 3,
      desc: "He recibido mas productos de los pedidos",
    },
    {
      id: 34,
      value: "3.4 No corresponde con la descripción web",
      idMadre: 3,
      desc: "No corresponde con la descripción web",
    },
    {
      id: 35,
      value: "3.5 Otros motivos errores entregas",
      idMadre: 3,
      desc: "Otros motivos errores entregas",
    },
    {
      id: 41,
      value: "4.1 Rechazado cliente en la entrega",
      idMadre: 4,
      desc: "Rechazado cliente en la entrega",
    },
    {
      id: 42,
      value: "4.2 Agencia devuelve - Cliente ausente",
      idMadre: 4,
      desc: "Agencia devuelve - Cliente ausente",
    },
    {
      id: 43,
      value: "4.3 Agencia devuelve - No localizable (dirección)",
      idMadre: 4,
      desc: "Agencia devuelve - No localizable (direccion)",
    },
    {
      id: 44,
      value: "4.4 Otros motivos no entregado",
      idMadre: 4,
      desc: "Otros motivos no entregados",
    },
    {
      id: 51,
      value: "5.1 Cancelación - Ya no lo quiero",
      idMadre: 5,
      desc: "Cancelacion - Ya no lo quiero",
    },
    {
      id: 52,
      value: "5.2 Cancelación - Error en la realización",
      idMadre: 5,
      desc: "Cancelacion - Error en la realizacion",
    },
    {
      id: 53,
      value: "5.3 Cancelación - Otros motivos",
      idMadre: 5,
      desc: "Cancelacion - Otros motivos",
    },
    {
      id: 54,
      value: "5.4 Modificación - Dirección de entrega",
      idMadre: 5,
      desc: "Modificacion - Direccion de entrega",
    },
    {
      id: 55,
      value: "5.5 Otros motivos canacel/modif. Pedido",
      idMadre: 5,
      desc: "Otros motivos cancel/modif. Pedido",
    },
    {
      id: 61,
      value: "6.1 He pagado pero no me aparece el pedido",
      idMadre: 6,
      desc: "He pagado, pero no me aparece el pedido",
    },
    {
      id: 62,
      value: "6.2 Pago incompleto",
      idMadre: 6,
      desc: "Pago incompleto",
    },
    {
      id: 63,
      value: "6.3 Otros motivos pagos",
      idMadre: 6,
      desc: "Otros motivos pagos",
    },
    {
      id: 71,
      value: "7.1 Errores en facturas (precios/dtos)",
      idMadre: 7,
      desc: "Errores en las facturas (precios/dtos)",
    },
    {
      id: 72,
      value: "7.2 Otros motivos facturación/documentación",
      idMadre: 7,
      desc: "Otros motivos facturacion/documentacion",
    },
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "55px" }}
    >
      <div style={{ maxWidth: "100%" }}>
        <div
          className="imagen"
          style={{
            display: "flex",
            alignItems: "center",
            felxDirection: "column",
          }}
        >
          <h1 style={{ width: "100%", color: "white" }}>
            Formulario Asistencia Postventa
          </h1>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ maxWidth: "1000px", alignItems: "center" }}>
            <form
              onSubmit={handleSubmit(handleSend)}
              noValidate
              autoComplete="off"
              style={{ margin: isMobile ? "0 10px" : null }}
            >
              <h4>Datos personales</h4>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Nombre*"
                    style={{ width: "100%" }}
                    {...register("nombre", {
                      required: {
                        value: true,
                        message: "El nombre es necesario.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="nombre"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Primer apellido*"
                    style={{ width: "100%" }}
                    {...register("primerApellido", {
                      required: {
                        value: true,
                        message: "El primer apellido es necesario.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="primerApellido"
                    className="errorMessage"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="Segundo apellido"
                    {...register("segundoApellido")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="DNI/NIE*"
                    {...register("dni", {
                      required: "El DNI/NIE es necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dni"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="Dirección de entrega*"
                    {...register("direccion", {
                      required: "La dirección de entrega es necesaria.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="direccion"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Código Postal*"
                    style={{ width: "100%" }}
                    {...register("codigoPostal", {
                      required: "El códgo postal es necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="codigoPostal"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    className="textField"
                    id="pais"
                    options={[
                      { id: 1, value: "España" },
                      { id: 2, value: "Portugal" },
                    ]}
                    value={pais}
                    getOptionLabel={(option) => option.value}
                    name="pais"
                    onChange={(event, newInputValue) => {
                      setPais(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="País*"
                        {...register("pais", {
                          required: "El país es necesario.",
                        })}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pais"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Provincia*"
                    style={{ width: "100%" }}
                    {...register("provincia", {
                      required: "La provincia es necesaria.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="provincia"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="Ciudad/Municipio*"
                    {...register("ciudad", {
                      required: "La ciudad o el municipio son necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="ciudad"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Teléfono*"
                    style={{ width: "100%" }}
                    {...register("telefono", {
                      required: "El teléfono es necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="telefono"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="Email*"
                    {...register("email", {
                      required: "El email es necesario.",
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
              </Grid>
              <h4 className="subtitle" style={{ margin: "1rem 0" }}>
                Datos de la incidencia
              </h4>
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                style={{ marginBottom: "15px" }}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        className="textField"
                        style={{ width: "100%" }}
                        label="Número de pedido*"
                        {...register("numped", {
                          required: "El número de pedido es necesario.",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="numped"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        className="textField"
                        id="tipo_incidencia"
                        options={tipos_incidencias}
                        value={tipo}
                        getOptionLabel={(option) => option.value}
                        name="tipo_incidencia"
                        onChange={(event, newInputValue) => {
                          cargarSubtipos(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo*"
                            {...register("tipo_incidencia", {
                              required: "El tipo de incidencia es necesario.",
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="tipo_incidencia"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        className="textField"
                        id="subtipo_incidencia"
                        options={subtipos}
                        value={subtipo}
                        onChange={(event, newInputValue) => {
                          setSubtipo(newInputValue);
                        }}
                        getOptionLabel={(option) => option.value}
                        name="subtipo_incidencia"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Subtipo*"
                            {...register("subtipo_incidencia", {
                              required:
                                "El subtipo de incidencia es necesario.",
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="subtipo_incidencia"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    multiline
                    rows={7}
                    variant="outlined"
                    placeholder="Descripción del problema"
                    label="Descripción del problema*"
                    {...register("descripcion", {
                      required: "La descripción del problema es necesaria.",
                    })}
                    style={{ width: "100%" }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="descripcion"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      marginBottom: "25px",
                      color: "black",
                      backgroundColor: "white",
                      border: "1px solid",
                      boxShadow: "none",
                      borderColor: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        opacity: "0.9",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Seleccionar archivo
                    <input
                      type="file"
                      name="imagen"
                      onChange={(event) => {
                        setImagen(event.target.files[0]);
                      }}
                      hidden
                    />
                  </Button>
                  <span
                    style={{
                      paddingLeft: 10,
                      color: "grey",
                    }}
                  >
                    {imagen
                      ? imagen.name
                      : "Añade una imagen del producto dañado."}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        read
                        onChange={(event, newInputValue) => {
                          setRead(newInputValue);
                        }}
                      />
                    }
                    label={
                      <p>
                        He leído y acepto las{" "}
                        <a
                          style={{ color: "grey" }}
                          href="https://lifetimestore.es/pages/condiciones-generales"
                        >
                          condiciones de compra
                        </a>{" "}
                        y la{" "}
                        <a
                          style={{ color: "grey" }}
                          href="https://lifetimestore.es/pages/politica-de-privacidad"
                        >
                          política de privacidad
                        </a>
                        .
                      </p>
                    }
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="outlined"
                sx={styles}
                style={{ marginBottom: "20px", color: "black" }}
                disabled={read === false || loading === true}
              >
                {loading ? (
                  <ReactLoading type={"bubbles"} color="#black" />
                ) : (
                  <span>Enviar</span>
                )}
              </Button>
            </form>
          </div>
        </div>
        <img
          src={require("../../assets/body/lifetime_footer.jpg")}
          alt="lifetime_header"
          style={{ width: "100%" }}
        />
        <div
          style={{
            backgroundColor: "#303030",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ color: "white" }}>
            @{new Date().getFullYear()} LIFETIME |{" "}
            <strong>Todos los derechos reservados</strong>
          </p>
        </div>
      </div>
      <ModalSuccessError
        open={openSuccess}
        type="success"
        text="En breve gestionaremos su consulta y le daremos una respuesta."
      />

      <ModalSuccessError
        open={openError}
        type="error"
        text="Parece que algo no ha ido bien. Inténtalo de nuevo más tarde."
      />
    </div>
  );
};

export default Incidencias;
