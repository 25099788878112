import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Consultas from "./pages/consultas/consultas.container";
import Incidencias from "./pages/incidencias/incidencias.container";
import ResponsiveAppBar from "./components/navbar";



function App() {
  return (
    <div className="App">
        <ResponsiveAppBar style={{height: '20px'}}/>
        <Routes>
          <Route path="/incidencias" element={< Incidencias/>} />
          <Route path="/consultas" element={< Consultas/>} />
        </Routes>
      </div>
  );
}

export default App;
