import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import "./consultas.container.scss";
import { crearConsulta } from "../../services/service";
import "react-notifications/lib/notifications.css";
import ReactLoading from "react-loading";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ModalSuccessError from "../../components/modal-success-error";

const styles = {
  "&.MuiButton-root": {
    border: "2px black solid",
  },
};

const Consultas = () => {
  const [subtipos, setSubtipos] = useState([]);
  const [tipo, setTipo] = useState();
  const [pais, setPais] = useState();
  const [subtipo, setSubtipo] = useState();
  const [loading, setLoading] = useState(false);
  const [read, setRead] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleSend = async (data) => {
    setLoading(true);
    data = {
      ...data,
      tipo_consulta: tipo.desc,
      subtipo_consulta: subtipo.desc,
      pais: pais.value
    };
    const res = await crearConsulta(data);
    if (res.status === 200) {
      setOpenSuccess(true);
    } else {
      setOpenError(true);
    }
    setLoading(false);
  };

  const cargarSubtipos = (tipo_selected) => {
    const sub = subtipos_consultas.filter(
      (subtipo) => subtipo.idMadre === tipo_selected?.id
    );
    setSubtipo(null);
    setSubtipos(tipo_selected ? sub : []);
    setTipo(tipo_selected);
  };

  const tipos_consultas = [
    { id: 1, value: "1. Productos", desc: "Productos" },
    { id: 2, value: "2. Pedidos", desc: "Pedidos" },
    { id: 3, value: "3. Medios de pago", desc: "Medios de pago" },
    {
      id: 4,
      value: "4. Condiciones de servicio",
      desc: "Condiciones de servicio",
    },
    {
      id: 5,
      value: "5. Devoluciones y cancelaciones",
      desc: "Devoluciones y cancelaciones",
    },
    { id: 6, value: "6. Gestion/Compra online", desc: "Gestión/Compra online" },
    {
      id: 7,
      value: "7. Facturación/Documentación",
      desc: "Facturación/Documentación",
    },
    {
      id: 8,
      value: "8.Tecnicas /Funcionamiento",
      desc: "Técnicas/Funcionamiento",
    },
    {
      id: 9,
      value: "9. Garantía/Postventa",
      desc: "Garantía/Postventa",
    },
    {
      id: 10,
      value: "10.Otros",
      desc: "Otros",
    },
  ];

  const subtipos_consultas = [
    {
      id: 11,
      value: "1.1 Caracteristicas del producto",
      idMadre: 1,
      desc: "Caracteristicas del producto",
    },
    {
      id: 12,
      value: "1.2 Disponibilidad (Stock)",
      idMadre: 1,
      desc: "Disponibilidad (Stock)",
    },
    {
      id: 13,
      value: "1.3 Lugares de compra",
      idMadre: 1,
      desc: "Lugares de compra",
    },
    {
      id: 14,
      value: "1.4 Precios/Tarifas",
      idMadre: 1,
      desc: "Precios/Tarifas",
    },
    {
      id: 15,
      value: "1.5 Venta Productos quimicos Spas/Piscinas",
      idMadre: 1,
      desc: "Venta Productos quimicos Spas/Piscinas",
    },
    {
      id: 16,
      value: "1.6 Venta a Canarias/Latam/Resto Mundo",
      idMadre: 1,
      desc: "Venta a Canarias/Latam/Resto Mundo",
    },

    {
      id: 17,
      value: "1.7 Compra de repuestos",
      idMadre: 1,
      desc: "Compra de repuestos",
    },

    {
      id: 18,
      value: "1.6 Venta a Canarias/Latam/Resto Mundo",
      idMadre: 1,
      desc: "Otras consultas Productos",
    },
    {
      id: 21,
      value: "2.1 Situación/estado del pedido",
      idMadre: 2,
      desc: "Situacion/estado del pedido",
    },
    {
      id: 22,
      value: "2.2 No he recibido correo de confirmación del pedido",
      idMadre: 2,
      desc: "No he recibido correo de confirmacion del pedido",
    },
    {
      id: 23,
      value: "2.3 Fecha de recepción estimada/Tiempo de entrega",
      idMadre: 2,
      desc: "Fecha de recepcion estimada/Tiempo de entrega",
    },
    {
      id: 24,
      value: "2.4 Entrega parcial",
      idMadre: 2,
      desc: "Entrega parcial",
    },

    {
      id: 25,
      value: "2.5 Pedido no entregado",
      idMadre: 2,
      desc: "Pedido no entregado",
    },

    {
      id: 26,
      value: "2.6 Número de bultos/Pallets",
      idMadre: 2,
      desc: "Numero de bultos/Pallets",
    },

    {
      id: 27,
      value: "2.7 Tramitación incidencia en un pedido",
      idMadre: 2,
      desc: "Tramitacion incidencia en un pedido",
    },

    {
      id: 28,
      value: "2.8 Como encuentro mi numero de pedido",
      idMadre: 2,
      desc: "Como encuentro mi numero de pedido",
    },

    {
      id: 29,
      value: "2.9 Cual es la agencia de envio",
      idMadre: 2,
      desc: "Cual es la agencia de envio",
    },

    {
      id: 30,
      value: "2.10 Otras consultas pedidos",
      idMadre: 2,
      desc: "Otras consultas pedidos",
    },
    {
      id: 31,
      value: "3.1 Medios de pago disponibles",
      idMadre: 3,
      desc: "Medios de pago disponibles",
    },
    {
      id: 32,
      value: "3.2 Comisiones aplicadas",
      idMadre: 3,
      desc: "Comisiones aplicadas",
    },
    {
      id: 33,
      value: "3.3 Financiación del pago",
      idMadre: 3,
      desc: "Financiacion del pago",
    },
    {
      id: 34,
      value: "3.4 Otras consultas medios de pago",
      idMadre: 3,
      desc: "Otras consultas medios de pago",
    },
    {
      id: 41,
      value: "4.1 Tiempos de entrega",
      idMadre: 4,
      desc: "Tiempos de entrega",
    },
    {
      id: 42,
      value: "4.2 Gastos de envio",
      idMadre: 4,
      desc: "Gastos de envio",
    },
    {
      id: 43,
      value: "4.3 Otras consultas condiciones de servicio",
      idMadre: 4,
      desc: "Otras consultas condiciones de servicio",
    },
    {
      id: 51,
      value: "5.1 Condiciones cancelación pedido",
      idMadre: 5,
      desc: "Condiciones cancelacion pedido",
    },
    {
      id: 52,
      value: "5.2 Como cancelar mi pedido",
      idMadre: 5,
      desc: "Como cancelar mi pedido",
    },
    {
      id: 53,
      value: "5.3 Condiciones devolucion de pedido",
      idMadre: 5,
      desc: "Condiciones devolucion de pedido",
    },
    {
      id: 54,
      value: "5.4 Como devolver mi pedido",
      idMadre: 5,
      desc: "Como devolver mi pedido",
    },
    {
      id: 55,
      value: "5.5 Reembolsos no recibidos",
      idMadre: 5,
      desc: "Reembolsos no recibidos",
    },
    {
      id: 56,
      value: "5.6 Otras consultas Devoluciones y cancelaciones",
      idMadre: 5,
      desc: "Otras consultas Devoluciones y cancelaciones",
    },
    {
      id: 61,
      value: "6.1 Como accedo a mi cuenta",
      idMadre: 6,
      desc: "Como accedo a mi cuenta",
    },
    {
      id: 62,
      value: "6.2 Como recupero mi contraseña",
      idMadre: 6,
      desc: "Como recupero mi contraseña",
    },
    {
      id: 63,
      value: "6.3 Todos los productos estan disponibles",
      idMadre: 6,
      desc: "Todos los productos estan disponibles",
    },
    {
      id: 64,
      value: "6.4 Otras consultas Gestión/compra online",
      idMadre: 6,
      desc: "Otras consultas Gestion/compra online",
    },
    {
      id: 71,
      value: "7.1 Solicitud de factura/albaran",
      idMadre: 7,
      desc: "Solicitud de factura/albaran",
    },
    {
      id: 72,
      value: "7.2 Cambio de datos factura",
      idMadre: 7,
      desc: "Cambio de datos factura",
    },
    {
      id: 73,
      value: "7.3 Otas consultas facturación/documentación",
      idMadre: 7,
      desc: "Otras consultas facturacion/documentacion",
    },
    {
      id: 81,
      value: "8.1 Cuidado y limpieza",
      idMadre: 8,
      desc: "Cuidado y limpieza",
    },
    {
      id: 82,
      value: "8.2 Mantenimiento y Reparaciones",
      idMadre: 8,
      desc: "Mantenimiento y Reparaciones",
    },
    {
      id: 83,
      value: "8.3 Montaje, uso y llenado",
      idMadre: 8,
      desc: "Montaje, uso y llenado",
    },
    {
      id: 84,
      value: "8.4 Carga de uso piscinas y Spas",
      idMadre: 8,
      desc: "Carga de uso piscinas y Spas",
    },
    {
      id: 85,
      value: "8.5 SPAs",
      idMadre: 8,
      desc: "SPAs",
    },
    {
      id: 86,
      value: "8.6 Piscinas",
      idMadre: 8,
      desc: "Piscinas",
    },
    {
      id: 87,
      value: "8.7 Depuradoras y cloradores",
      idMadre: 8,
      desc: "Depuradoras y cloradores",
    },
    {
      id: 88,
      value: "8.8 Accesorios piscinas",
      idMadre: 8,
      desc: "accesorios piscinas",
    },
    {
      id: 89,
      value: "8.9 Otras consultas tecnicas/funcionamiento",
      idMadre: 8,
      desc: "Otras consultas tecnicas/funcionamiento",
    },
    {
      id: 91,
      value: "9.1 Garantia de los productos",
      idMadre: 9,
      desc: "Garantia de los productos",
    },
    {
      id: 92,
      value: "9.2 Como gestiono mi garantia",
      idMadre: 9,
      desc: "Como gestiono mi garantia",
    },
    {
      id: 93,
      value: "9.3 Servicios fuera de garantia",
      idMadre: 9,
      desc: "Servicios fuera de garantia",
    },
    {
      id: 94,
      value: "9.4 Otras consultas de Garantia/Postventa",
      idMadre: 9,
      desc: "Otras consultas de garantia/Postventa",
    },
    {
      id: 100,
      value: "10.Otros",
      idMadre: 10,
      desc: "Otros",
    },
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "55px" }}
    >
      <div style={{ maxWidth: "100%" }}>
        <div
          className="imagen"
          style={{
            display: "flex",
            alignItems: "center",
            felxDirection: "column",
          }}
        >
          <h1 style={{ width: "100%", color: "white" }}>
            Formulario de Contacto
          </h1>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ maxWidth: "1000px", alignItems: "center" }}>
            <form
              onSubmit={handleSubmit(handleSend)}
              noValidate
              autoComplete="off"
              style={{ margin: isMobile ? "0 10px" : null }}
            >
              <h4>Datos personales</h4>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Nombre*"
                    style={{ width: "100%" }}
                    {...register("nombre", {
                      required: {
                        value: true,
                        message: "El nombre es necesario.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="nombre"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Apellidos*"
                    style={{ width: "100%" }}
                    {...register("apellidos", {
                      required: {
                        value: true,
                        message: "Los apellidos son necesarios.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="apellidos"
                    className="errorMessage"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="Ciudad/Municipio*"
                    {...register("ciudad", {
                      required: "La ciudad o el municipio son necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="ciudad"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Código Postal*"
                    style={{ width: "100%" }}
                    {...register("codigoPostal", {
                      required: "El códgo postal es necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="codigoPostal"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Provincia*"
                    style={{ width: "100%" }}
                    {...register("provincia", {
                      required: "La provincia es necesaria.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="provincia"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    className="textField"
                    id="pais"
                    options={[
                      { id: 1, value: "España" },
                      { id: 2, value: "Portugal" },
                    ]}
                    value={pais}
                    getOptionLabel={(option) => option.value}
                    name="pais"
                    onChange={(event, newInputValue) => {
                      setPais(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="País*"
                        {...register("pais", {
                          required: "El país es necesario.",
                        })}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pais"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    label="Teléfono*"
                    style={{ width: "100%" }}
                    {...register("telefono", {
                      required: "El teléfono es necesario.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="telefono"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    className="textField"
                    style={{ width: "100%" }}
                    label="Email*"
                    {...register("email", {
                      required: "El email es necesario.",
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
              </Grid>
              <h4 className="subtitle" style={{ margin: "1rem 0" }}>
                Datos de la consulta
              </h4>
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                style={{ marginBottom: "15px" }}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Autocomplete
                        className="textField"
                        id="tipo_consulta"
                        options={tipos_consultas}
                        value={tipo}
                        getOptionLabel={(option) => option.value}
                        name="tipo_consulta"
                        onChange={(event, newInputValue) => {
                          cargarSubtipos(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo*"
                            {...register("tipo_consulta", {
                              required: "El tipo de consulta es necesario.",
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="tipo_consulta"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        className="textField"
                        id="subtipo_consulta"
                        options={subtipos}
                        value={subtipo}
                        onChange={(event, newInputValue) => {
                          setSubtipo(newInputValue);
                        }}
                        getOptionLabel={(option) => option.value}
                        name="subtipo_consulta"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Subtipo*"
                            {...register("subtipo_consulta", {
                              required: "El subtipo de consulta es necesario.",
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="subtipo_consulta"
                        render={({ message }) => (
                          <p className="redColor">{message}</p>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder="Descripción del problema"
                    label="Descripción del problema*"
                    {...register("descripcion", {
                      required: "La descripción del problema es necesaria.",
                    })}
                    style={{ width: "100%" }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="descripcion"
                    render={({ message }) => (
                      <p className="redColor">{message}</p>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        read
                        onChange={(event, newInputValue) => {
                          setRead(newInputValue);
                        }}
                      />
                    }
                    label={
                      <p>
                        He leído y acepto las{" "}
                        <a
                          style={{ color: "grey" }}
                          href="https://lifetimestore.es/pages/condiciones-generales"
                        >
                          condiciones de compra
                        </a>{" "}
                        y la{" "}
                        <a
                          style={{ color: "grey" }}
                          href="https://lifetimestore.es/pages/politica-de-privacidad"
                        >
                          política de privacidad
                        </a>
                        .
                      </p>
                    }
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="outlined"
                sx={styles}
                style={{ marginBottom: "20px", color: "black" }}
                disabled={read === false || loading === true}
              >
                {loading ? (
                  <ReactLoading type={"bubbles"} color="#black" />
                ) : (
                  <span>Enviar</span>
                )}
              </Button>
            </form>
          </div>
        </div>
        <img
          src={require("../../assets/body/lifetime_footer.jpg")}
          alt="lifetime_header"
          style={{ width: "100%" }}
        />
        <div
          style={{
            backgroundColor: "#303030",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ color: "white" }}>
            @{new Date().getFullYear()} LIFETIME |{" "}
            <strong>Todos los derechos reservados</strong>
          </p>
        </div>
      </div>
      <ModalSuccessError
        open={openSuccess}
        type="success"
        text="En breve gestionaremos su consulta y le daremos una respuesta."
      />

      <ModalSuccessError
        open={openError}
        type="error"
        text="Parece que algo no ha ido bien. Inténtalo de nuevo más tarde."
      />
    </div>
  );
};

export default Consultas;
