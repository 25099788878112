import axios from "axios";
import "../config";

export const crearTicket = async (data) => {
    return await axios
      .post(global.ruta + "/salesforce/incidencia", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  export const crearConsulta = async (data) => {
   
    return await axios
      .post(global.ruta + "/salesforce/consulta", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  export const addImagen = async (nombre, imagen, idTicket) => {
    const config = {
      params: { access_token: global.token, nombre: nombre, ticket: idTicket },
      body: imagen,
    };

    return await axios
    .post(global.ruta + "/salesforce/addImage", { imagen: imagen }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
  }