import { Box, Button, Modal } from "@mui/material";
import React from "react";
import SuccessLogo from "../assets/images/success.png";
import ErrorLogo from "../assets/images/error.png";

const ModalSuccessError = (props) => {
  const styles = {
    "&.MuiButton-root": {
      border: "2px black solid",
    },
    style: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      minHeight: "300px",
      width: "250px",
      height: "auto",
      border: "1px solid ##E6E6E6",
      boxShadow: 24,
      borderRadius: "20px",
      p: 4,
      paddingTop: 5,
    },
  };

  return (
    <Modal
      open={props?.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdrop: "static", keyboard: false }}
    >
      <Box sx={styles.style}>
        <div
          className="close"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-0.5rem 0 0 0",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {props?.type === "success" ? (
            <>
              <h2
                style={{
                  fontSize: "32px",
                  color: "#4D4D4D",
                  margin: "0 0 10px 0",
                }}
              >
                ¡Enviado!
              </h2>
              <p className="subtitle" style={{ textAlign: "center" }}>
                {props.text}
              </p>
              <img
                src={SuccessLogo}
                alt="success"
                height={"200px"}
                width={"auto"}
              />{" "}
              <Button
                type="submit"
                variant="outlined"
                sx={styles}
                style={{ marginTop: "30px", color: "black" }}
                href="https://lifetimestore.es/"
              >
                Volver
              </Button>
            </>
          ) : null}
          {props?.type === "error" ? (
            <>
              <h2
                style={{
                  fontSize: "32px",
                  color: "#4D4D4D",
                  margin: "0 0 10px 0",
                }}
              >
                ¡Ups!
              </h2>
              <p className="subtitle" style={{ textAlign: "center" }}>
                {props.text}
              </p>
              <img
                src={ErrorLogo}
                alt="error"
                height={"150px"}
                width={"auto"}
              />{" "}
              <Button
                type="submit"
                variant="outlined"
                sx={styles}
                style={{ marginBottom: "20px", color: "black" }}
                href="https://lifetimestore.es/"
              >
                Volver
              </Button>
            </>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalSuccessError;
