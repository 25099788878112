import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LogoLifetime from "../assets/logo/logo-lifetime-black.png";
import { Link } from "react-router-dom";

const ResponsiveAppBar = () => {
  const pages = [
    { id: "Mesas Plegables", ref: "mesas-plegables" },
    { id: "Sillas Plegables", ref: "sillas-plegables" },
    { id: "Casetas de Jardín", ref: "casetas-de-jardin" },
    { id: "Almacenaje Exterior", ref: "almacenaje-exterior" },
    { id: "Canastas de Baloncesto", ref: "canastas-de-baloncesto" },
    { id: "Horeca", ref: "horeca" },
    { id: "Kayak", ref: "kayak" },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "white", color: "black" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ height: "10px" }}>
          <a href="http://lifetimestore.es">
            <Box
              sx={{
                display: { xs: "none", lg: "flex" },
                height: "60px",
                width: "auto",
              }}
            >
              <img src={LogoLifetime} alt="logo_lifetime" />
            </Box>
          </a>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <a
                    href={"https://lifetimestore.es/collections/" + page.ref}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <Typography textAlign="center">{page.id}</Typography>
                  </a>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <a href="http://lifetimestore.es">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
              <img src={LogoLifetime} alt="logo_lifetime" />
            </Box>
          </a>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.id}
                onClick={handleCloseNavMenu}
                className="boton"
                href={"https://lifetimestore.es/collections/" + page.ref}
                sx={{
                  color: "black",
                  display: "block",
                  fontSize: "1rem",
                  fontFamily: "Roboto, sans-serif",
                  marginRight: "20px",
                }}
              >
                {page.id}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
